import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

//1. Import coingecko-api
import CoinGecko from 'coingecko-api'

const App = () => {
  const CoinGeckoClient = new CoinGecko()
  const [markets, setMarkets] = useState([])
  const [marketChart, setMarketChart] = useState([])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMarkets()
      fetchMarketChart()
    }, 1000)
    return () => clearInterval(interval)

    async function fetchMarkets() {
      await CoinGeckoClient.coins
        .markets({
          vs_currency: 'usd',
          order: 'market_cap_desc',
          per_page: 100,
          page: 1,
          sparkline: false,
          price_change_percentage: '24h',
        })
        .then((data) => {
          console.log(data)
          setMarkets(data.data)
        })
    }

    async function fetchMarketChart() {
      await CoinGeckoClient.coins
        .fetchMarketChart('bitcoin', {
          days: 1,
          vs_currency: 'usd',
        })
        .then((data) => {
          console.log(data.data.prices)
          //setMarketChart =
        })
    }
  }, [])

  return (
    <div>
      <h1>Volatility - Coinraptor.net</h1>
      <ul>
        {markets.map((coin) => {
          return (
            <li key={coin.id}>
              Coin: {coin.name}| Price: {coin.current_price}| Change:
              {coin.price_change_percentage_24h}%
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default App
